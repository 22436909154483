import React from 'react'
import { useState } from 'react'
import SidebarItem from './SidebarItem';
import { withRouter } from 'react-router'
import { History } from 'history'
import { logOutUser } from '../services/UserService';

export interface ISidebarProps {
    history: History,
}

let width = window.innerWidth

function Sidebar(props: ISidebarProps) {

    const currentUrl = props.history.location.pathname;

    const [state, setState] = useState({ active: false })

    const changeRoute = (path: string) => {
        props.history.push(path);
        if (width <= 768) {
            toggleSidebar()
        }
    }

    const toggleSidebar = () => setState({ ...state, active: !state.active });

    return (
        <div>
            <div className={'sidebar ' + (state.active ? 'sidebar--active' : '')} >
                <i className="material-icons sidebar-item__menu " onClick={toggleSidebar}>menu</i>
                <div className="sidebar__logo" />
                <SidebarItem text='Order Words' icon='apps' path='/order-words' currentUrl={currentUrl} changeRoute={changeRoute} />
                <SidebarItem text='Quiz Questions' icon='help_outline' path='/quiz-questions' currentUrl={currentUrl} changeRoute={changeRoute} />
                <SidebarItem text='Word Search' icon='search' path='/word-search' currentUrl={currentUrl} changeRoute={changeRoute} />
                <div className="sidebar-profile">
                    <div className='sidebar-avatar' />
                    <span className='sidebar-profile__user-name'>Femija Musliman</span>
                    <span className='sidebar__logout' onClick={logOutUser}>Log out</span>
                </div>
                {!state.active && <div className='sidebar__switcher-layer' />}

            </div>
            {width <= 768 && <div style={{ minWidth: 34, minHeight: '100vh' }} />}

        </div>
    )
}


export default withRouter(Sidebar)