import OrderWords from "../../../models/OrderWords";
import { OrderWordsAction } from "./orderWordsAction";
import { OrderWordsTypes } from "./orderWordsActionTypes";


export const getAllOrderWordsAction = (orderWords: OrderWords[]) => {
    const GetAllOrderWordsAction: OrderWordsAction = {
        type: OrderWordsTypes.GET_ORDER_WORDS,
        payload: orderWords
    }

    return GetAllOrderWordsAction;
}

export const selectOrderWordsAction = (orderWords: OrderWords) => {
    const SelectOrderWordsAction: OrderWordsAction = {
        type: OrderWordsTypes.SELECT_ORDER_WORDS,
        payload: orderWords
    }

    return SelectOrderWordsAction;
}

export const addOrderWordsAction = (orderWords: OrderWords) => {
    const AddOrderWordsAction: OrderWordsAction = {
        type: OrderWordsTypes.ADD_ORDER_WORDS,
        payload: orderWords
    }

    return AddOrderWordsAction;
}


export const updateOrderWordsAction = (orderWords: OrderWords) => {
    const UpdateOrderWordsAction: OrderWordsAction = {
        type: OrderWordsTypes.UPDATE_ORDER_WORDS,
        payload: orderWords
    }

    return UpdateOrderWordsAction;
}

export const deleteOrderWordsAction = (orderWords: OrderWords) => {
    const DeleteOrderWordsAction: OrderWordsAction = {
        type: OrderWordsTypes.DELETE_ORDER_WORDS,
        payload: orderWords
    }

    return DeleteOrderWordsAction;
}


export const resetCurrentOrderWords = (orderWords: OrderWords) => {
    const ResetCurrentOrderWords: OrderWordsAction = {
        type: OrderWordsTypes.RESET_ORDER_WORDS,
        payload: orderWords
    }

    return ResetCurrentOrderWords;
}