import React from 'react'
import OrderWords from '../../models/OrderWords';


export interface IListItemProps {
    text: any,
    onClick:any,
    currentId:any,
    id:any,
    orderWords:OrderWords
}
export default function ListItem(props: IListItemProps) {
  
    const active = props.currentId === props.id;
    return (
        <div className={'list__list-item ' + (active ? 'list__list-item--active' : '' )} onClick={props.onClick}>
            <span className="list-item__title">{props.orderWords.level + '. '}{props.text}</span>
            {active ? <i className="material-icons edit-mode__icon">create</i> : ''}

        </div>
    )
}
