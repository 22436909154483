
import { store } from '../redux';
import { API } from "../config";
import { getAllWordSearchesAction, addWordSearchesAction, updateWordSearchesAction, resetWordSearchesAction } from '../redux/actions/WordSearch/wordSearchesActionCreator';
import { WordSearches } from '../models/WordSearches';

import axios, { AxiosResponse } from 'axios';

export const getAllWordsSearches = async () => {
    axios.get(API + 'word-searches')
        .then((response:AxiosResponse) => {
            store.dispatch(getAllWordSearchesAction(response.data));
        })
}

export const addWordSearches = async (wordSearches: WordSearches) => {
    await axios.post(API + 'word-searches', wordSearches)
        .then((response:AxiosResponse) => {
            store.dispatch(addWordSearchesAction(response.data));
            store.dispatch(resetWordSearchesAction({}));
        })
}


export const updateWordSearches = async (wordSearches: WordSearches) => {

    await axios.put(API + `word-searches/${wordSearches.id}`, wordSearches)
        .then((response:AxiosResponse) => {
            store.dispatch(updateWordSearchesAction(response.data));
            store.dispatch(resetWordSearchesAction({}));

        })
}



