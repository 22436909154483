import QuizQuestion from "../../models/QuizQuestion";
import { QuizQuestionsAction } from "../actions/QuizQuestions/quizQuestionsAction";
import { QuizQuestionsTypes } from "../actions/QuizQuestions/quizQuestionsType";


const initialState: QuizQuestion = {};

export default (state = initialState, action: QuizQuestionsAction) => {
    switch (action.type) {
        case QuizQuestionsTypes.SELECT_QUESTION:
            return action.payload;
        case QuizQuestionsTypes.RESET_QUESTION:
            return action.payload;
        default:
            return state;
    }
}