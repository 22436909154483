import OrderWords from "../../models/OrderWords";
import { OrderWordsAction } from "../actions/OrderWords/orderWordsAction";
import { OrderWordsTypes } from "../actions/OrderWords/orderWordsActionTypes";

const initialState: OrderWords = {};

export default (state = initialState, action: OrderWordsAction) => {
    switch (action.type) {
        case OrderWordsTypes.SELECT_ORDER_WORDS:
            return action.payload;
        case OrderWordsTypes.RESET_ORDER_WORDS:
            return action.payload;
        default:
            return state;
    }
}