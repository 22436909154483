import { ContentAction } from "../actions/Content/contentAction";
import { ContentTypes } from "../actions/Content/contentActionTypes";

const initailState:boolean = false;
export default (state=initailState,action:ContentAction) => {

    switch(action.type) {

        case ContentTypes.TOGGLE_MENU:
            return action.payload;
        default:
            return state;
    }
}