import QuizQuestion from "../../../models/QuizQuestion";
import { QuizQuestionsAction } from "./quizQuestionsAction";
import { QuizQuestionsTypes } from "./quizQuestionsType";


export const getAllQuestionsAction = (questions: QuizQuestion[]) => {
    const GetAllQuestionsAction: QuizQuestionsAction = {
        type: QuizQuestionsTypes.GET_QUESTIONS,
        payload: questions
    }

    return GetAllQuestionsAction;
}


export const addQuestionAction = (question: QuizQuestion) => {
    const AddQuestionAction: QuizQuestionsAction = {
        type: QuizQuestionsTypes.ADD_QUESTION,
        payload: question
    }

    return AddQuestionAction;
}



export const updateQuestionAction = (question: QuizQuestion) => {
    const UpdateQuestionAction: QuizQuestionsAction = {
        type: QuizQuestionsTypes.UPDATE_QUESTION,
        payload: question
    }

    return UpdateQuestionAction;
}


export const selectQuestionAction = (question: QuizQuestion) => {
    const SelectQuestionAction: QuizQuestionsAction = {
        type: QuizQuestionsTypes.SELECT_QUESTION,
        payload: question
    }

    return SelectQuestionAction;
}


export const resetCurrentQuestionAction = (question: QuizQuestion) => {
    const ResetCurrentQuestionAction: QuizQuestionsAction = {
        type: QuizQuestionsTypes.RESET_QUESTION,
        payload: question
    }

    return ResetCurrentQuestionAction;
}

export const deleteCurrentQuestionAction = (question: QuizQuestion) => {
    const DeleteCurrentQuestionAction: QuizQuestionsAction = {
        type: QuizQuestionsTypes.DELETE_QUESTION,
        payload: question
    }

    return DeleteCurrentQuestionAction;
}