import React from 'react'

export interface IOptionProps {
    text: string,
    onDelete: (text: string) => void,
    className?: string,
    chooseAnswer?: (answer: string) => void
}

export default function OptionItem(props: IOptionProps) {

    const chooseAnswer = () =>{
        if(props.chooseAnswer) props.chooseAnswer(props.text);
    }
    return (
        <div className={'option-item ' + (props.className ? props.className : '')} >
            <span className='option-item__text width-100per' onClick={chooseAnswer}>{props.text}</span>
            <i className="material-icons option-item__close" onClick={() => props.onDelete(props.text)}>close</i>
        </div>
    )
}
