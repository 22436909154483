import React from 'react'
import Sidebar from '../../component/Sidebar';
import Content from './Content';
import { connect } from 'react-redux';
import { IReduxStore } from '../../redux';
import { Redirect } from 'react-router';


export interface IMainProps {
    user: any,
    dispatch: (action: any) => void
}

function Main(props: IMainProps) {


    React.useEffect(() => { }, [props.user]);


    return (
        <div className='main-page'>
            <Sidebar />

            <Content />

            {!props.user.id && <Redirect to='/login' />}
        </div>
    )
}

const mapStateToProps = (state: IReduxStore) => {
    
    return {
        user: state.user
    }
}
export default connect(mapStateToProps)(Main)