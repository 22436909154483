import React from 'react'
import { useState, useEffect } from 'react';
import Input from '../common/Input';
import Button from '../common/Button';
import OptionItem from '../common/OptionItem';
import Time from '../common/Time';
import { connect } from 'react-redux';
import { IReduxStore } from '../../redux';
import QuizQuestion from '../../models/QuizQuestion';
import { resetCurrentQuestionAction } from '../../redux/actions/QuizQuestions/quizQuestionsActionsCreator';
import { updateQuestion, addQuestions } from '../../services/QuizQuestionsService';
import { toast } from 'react-toastify';
import { toggleSidebarAction } from '../../redux/actions/Content/contentActionsCreator';


export interface IQuizQuestionsFormProps {
    currentQuestion: QuizQuestion,
    questions: QuizQuestion[],
    dispatch: (action: any) => void,
    className?: string,
    sidebarForm: boolean
}
function QuizQuestionsForm(props: IQuizQuestionsFormProps) {

    const questionMock: QuizQuestion = {};
    const [state, setState] = useState({
        optionValue: '',
        optionInputError: false,
        loading: false,
        quInputError: false,
        timeError: false,
        optionError: false,
        closeForm: false
    });

    const [question, setQuestion] = useState(questionMock);

    useEffect(() => {
        if (props.currentQuestion) {
            setQuestion(props.currentQuestion);
        }
    }, [props.currentQuestion]);

    useEffect(() => {
        setState({ ...state, closeForm: !props.sidebarForm });
    }, [props.sidebarForm]);

    const onQuestionChange = (qu: string) => {

        const currentQuestion = question;
        currentQuestion.level = +props.questions.length + 1;
        currentQuestion.question = qu;
        setState({ ...state, quInputError: false });
        setQuestion({ ...currentQuestion });
    }

    const onOptionChange = (value: string) => {
        setState({ ...state, optionValue: value })
    }

    const onTimeChange = (time: any) => {
        setQuestion({ ...question, time });
    }

    const renderOptions = () => {

        const options = question.options || [];
        return options.map((option, i) => {

            const active = question.answer === option;
            return <OptionItem
                key={i}
                text={option}
                onDelete={deleteOption}
                className={'question-form__option ' + (active ? 'question-form__option--active' : '')}
                chooseAnswer={chooseAnswer} />

        })

    }

    const chooseAnswer = (answer: string) => {
        setQuestion({ ...question, answer });
    }

    const addOption = () => {
        const options = question.options || [];
        if (state.optionValue !== '' && options.length + 1 <= 4) {
            setQuestion({ ...question, options: [...options, state.optionValue] });
            setState({ ...state, optionValue: '', optionInputError: false });
        } else {
            setState({ ...state, optionInputError: true });

        }
    }

    const addOptionByEnter = (e: any) => {
        var code = e.keyCode || e.which;
        if (code === 13) { //13 is the enter keycode
            //Do stuff in here
            const options = question.options || [];
            if (state.optionValue !== '' && options.length + 1 <= 4) {
                setQuestion({ ...question, options: [...options, state.optionValue] });
                setState({ ...state, optionValue: '', optionInputError: false });
            } else {
                setState({ ...state, optionInputError: true });

            }
        }
    }

    const deleteOption = (text: string) => {
        const options = question.options || [];
        var array = [...options];
        var index = array.indexOf(text)
        if (index !== -1) {
            if(array[index]===question.answer){
                question.answer='';
            }
            array.splice(index, 1);
          
            setQuestion({ ...question, options: array });
        }

    }


    const saveQuestion = async () => {

        const options = question.options || [];
        let error = false;

        if (options.length < 4) {
            toast.error('Keni me me pak se 4 opsione!');
            error = true;
        }

        if (question.answer === '' || !question.answer) {
            toast.error('Nuk keni zgjedhur pergjigjien e sakte!');
            error = true;

        }

        if (question.time === 0 || !question.time) {
            toast.error('Nuk keni caktuar kohen!');
            error = true;
        }

        if (error) return;

        if (props.currentQuestion.id && !state.quInputError && !state.timeError && !state.optionError) {
            setState({ ...state, loading: true });

            try {

                await updateQuestion(question);
                toast.success('Updated successfully');
                setQuestion(questionMock);
                props.dispatch(resetCurrentQuestionAction(questionMock));
                props.dispatch(toggleSidebarAction(false));


            } catch (e) {
                toast.error(e.Message);

            }
            setState({ ...state, loading: false, optionValue: '' });

        } else if (!state.quInputError && !state.timeError && !state.optionError) {
            setState({ ...state, loading: true });

            try {
                await addQuestions(question);
                toast.success('Saved successfully');
                setQuestion(questionMock);
                props.dispatch(resetCurrentQuestionAction(questionMock));
                props.dispatch(toggleSidebarAction(false));


            } catch (e) {
                toast.error(e.Message);

            }
            setState({ ...state, loading: false, optionValue: '' });

        }


    }

    const onCancelClicked = () => {
        props.dispatch(resetCurrentQuestionAction(questionMock));
        setQuestion(questionMock);
        setState({ ...state, optionValue: '' });
        props.dispatch(toggleSidebarAction(false));

    }
    return (
        <div className={'quiz-questions__question-form ' + (props.className && state.closeForm === false ? props.className : '')}>
            <span className='form__title'>Quiz Questions  Form</span>

            <Input
                type='text'
                placeholder='write question'
                className='mrb-10px'
                onChange={onQuestionChange}
                defaultValue={question.question ? question.question : ''} />

            <div className="flex width-100per responsive-wrapper">
                <Input
                    type='text'
                    placeholder='write options'
                    className='mrb-10px mrr-10px'
                    onChange={onOptionChange}
                    defaultValue={state.optionValue}
                    error={state.optionInputError}
                    onKeyPress={addOptionByEnter}  />

                <Button text='Add option' className='button--small' onClick={addOption} />
            </div>

            <div className="flex fl-col">
                <div className="question-form__options-list">
                    {renderOptions()}

                </div>
            </div>

            <Time onTimeChange={onTimeChange} time={question.time} />
            {state.timeError && <span className='error-msg mrt-10px'>Keni harruar te caktoni kohen!</span>}
            {state.quInputError && <span className='error-msg mrt-10px'>Pyetja nuk ekziston!</span>}
            {state.optionError && <span className='error-msg mrt-10px'>Nuk duhet te kete me pak se 4 opsione!</span>}
            <div className="order-words__bottom-btns mrt-10px ">
                <Button text='Cancel' onClick={onCancelClicked} className='button--white' icon='close' />
                <Button text={props.currentQuestion.id ? 'Update' : 'Save'} onClick={saveQuestion} loading={state.loading} icon='done' />
            </div>
        </div>
    )
}


const mapStateToProps = (state: IReduxStore) => {
    return {
        currentQuestion: state.currentQuestion,
        questions: state.quizQuestions,
        sidebarForm: state.sidebarForm
    }
}


export default connect(mapStateToProps)(QuizQuestionsForm);