import React from 'react'
import { WordSearches } from '../../models/WordSearches';


export interface IWordSearchesItemProps {
    wordSearches: WordSearches,
    onClick:any,
    currentId:any
}

export default function WordSearchesItem(props: IWordSearchesItemProps) {

    const renderWords = () => {
        const words = props.wordSearches.words || [];
       return words.map((word,i)=>{
           return <span key={i} className='word-search-item__word'>{word}</span>
        })
    }

    const active = props.currentId === props.wordSearches.id;
    return (
        <div className={'word-searches__word-searches-item ' + (active ? 'word-searches__word-searches-item--active' : '')} onClick={props.onClick}>
            <span className='word-search-item__grid-size'>Grid size : {props.wordSearches.gridSize}</span>
            <span className='word-search-item__level '>Level: {props.wordSearches.level}</span>

            <div className="word-search-item__words-list">
                {renderWords()}
            </div>

            {active ? <i className="material-icons question-item_edit-icon">create</i> : ''}

        </div>
    )
}
