import { UserActionTypes } from "../actions/User/UserActionTypes";

const initialState: any = {
    id: '',
    username: '',
    password: '',
    token: 'Basic Zm0tYWRtaW46Zm0tcGFzc3dvcmQ='
}
export default (state = initialState, action: any) => {
    switch (action.type) {

        case UserActionTypes.UPDATE_USER:

            return action.payload

        case UserActionTypes.LOGOUT_USER:

            return action.payload
        default:
            return state;
    }
}