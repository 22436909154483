import { RouterState, routerMiddleware } from 'connected-react-router';
import { createEpicMiddleware } from 'redux-observable';
import { Reducers } from './reducers/index'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware } from 'redux';
import OrderWords from '../models/OrderWords';
import QuizQuestion from '../models/QuizQuestion';
import { WordSearches } from '../models/WordSearches';




export interface IReduxStore {
    user:any,
    orderWordsList:OrderWords[],
    currentOrderWords:OrderWords,
    quizQuestions: QuizQuestion[],
    currentQuestion:QuizQuestion,
    currentWordSearches:WordSearches,
    wordSearches:WordSearches[],
    sidebarForm : boolean;
    router: RouterState,
}



const epicMiddleware = createEpicMiddleware();

export const history = createBrowserHistory();

export const store = createStore<IReduxStore, any, any, any>(Reducers(history), applyMiddleware(epicMiddleware, routerMiddleware(history)));
