import React from 'react'
import Loader from './Loader';

export interface IButtonProps {
    text: string,
    onClick?: () => void,
    className?: string,
    loading?:boolean,
    icon?:string
}
export default function Button(props: IButtonProps) {


    const onClick = () => {
        if (props.onClick) {
            props.onClick()
        }
    }
    return (
        <div
            className={'button ' + (props.className ? props.className : '')}
            onClick={onClick}
        >
            {props.icon && <i className="material-icons button-icon">{props.icon}</i>}
            {props.loading ? <Loader />: props.text}
        </div>
    )
}
