import React from 'react'
import { useState, useEffect } from 'react'

export interface IInputProps {
    placeholder: string,
    type: string,
    onChange?: any,
    defaultValue: any,
    inputRef?: (ref: any) => void,
    className?: string,
    error?: boolean,
    searchIcon?: boolean,
    emailIcon?: boolean,
    passwordIcon?: boolean,
    onKeyPress?:(e:any)=> void
}
export default function Input(props: IInputProps) {
    const inputEl = React.useRef(null);


    if (props.inputRef) {
        props.inputRef(inputEl);
    }

    const [state, setState] = useState({ value: props.defaultValue ? props.defaultValue : '', onFocus: false, showPw: false });

    useEffect(() => {  setState({ ...state, value: props.defaultValue }) }, [props.defaultValue]);

    const onChange = (e: any) => {
        setState({ ...state, value: e.target.value, onFocus: true });
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    }


    const toggleShowPw = () => {
        setState({ ...state, showPw: !state.showPw });
    }

    return (
        <div className={'input-wrapper '
            + (props.className ? props.className : '') + ' '
            + (state.onFocus ? 'input-wrapper--focus' : '') + ' '
            + (props.error ? 'input-wrapper--error' : '')}>
            {props.searchIcon && <i className="material-icons input__search-icon">search</i>}
            {props.emailIcon && <i className="material-icons input__search-icon">email</i>}
            {props.passwordIcon && <i className="material-icons input__search-icon">vpn_key</i>}
            <input
                type={state.showPw ? 'text' : props.type}
                placeholder={props.placeholder}
                onChange={onChange}
                value={state.value}
                className='input'
                onBlur={() => setState({ ...state, onFocus: false })}
                onFocus={() => setState({ ...state, onFocus: true })}
                ref={inputEl} 
                onKeyPress={props.onKeyPress}/>

            {(props.passwordIcon && !state.showPw) && <i className="material-icons input__search-icon pointer" onClick={toggleShowPw}>visibility_off</i>}
            {(props.passwordIcon && state.showPw) && <i className="material-icons input__search-icon pointer" onClick={toggleShowPw}>visibility</i>}

        </div>
    )
}
