import React from 'react'
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import HeaderContent from '../../component/common/HeaderContent';
import QuestionItem from '../../component/QuizQuestions/QuestionItem';
import QuizQuestionsForm from '../../component/QuizQuestions/QuizQuestionsForm';
import Input from '../../component/common/Input';
import QuizQuestion from '../../models/QuizQuestion';
import { getAllQuestions } from '../../services/QuizQuestionsService';
import { IReduxStore } from '../../redux';
import { selectQuestionAction } from '../../redux/actions/QuizQuestions/quizQuestionsActionsCreator';
import Loader from '../../component/common/Loader';
import Button from '../../component/common/Button';
import { toggleSidebarAction } from '../../redux/actions/Content/contentActionsCreator';

export interface IQuizQuestionsProps {
    questions: QuizQuestion[],
    currentQuestionId: any,
    dispatch: (action: any) => void,
    sidebarForm:boolean
}

let width = window.innerWidth


function QuizQuestions(props: IQuizQuestionsProps) {

    const questionsMock: QuizQuestion[] = [];
    const [state, setState] = useState({ questions: questionsMock, loader: true, searchValue: '',isOpenForm:false });

    useEffect(() => {

        if (state.loader) {
             getAllQuestions();
        }

        setState({ ...state, questions: props.questions, loader: false });
    }, [props.questions])

    useEffect(() => {
        setState({ ...state, isOpenForm: props.sidebarForm });
    }, [props.sidebarForm]);
  
    const selectQuestion = (question: QuizQuestion) => {
        props.dispatch(selectQuestionAction(question));
        props.dispatch(toggleSidebarAction(true));

    }

    const onChange = (e: string) => {

        const temp = [];
        setState({ ...state, searchValue: e });
        if (e) {
            for (let i in props.questions) {
                const questionItem = props.questions[i];
                const sentence = questionItem.question || '';
                if (sentence.toLowerCase().indexOf(e.toLowerCase()) !== -1) {

                    temp.push(questionItem);
                }
            }

            setState({ ...state, searchValue: e, questions: temp });
        } else {
            setState({ ...state, searchValue: e, questions: props.questions });

        }
    }
    const renderQuestions = () => {

        return state.questions.sort((a:any,b:any)=>  a.level - b.level).map(question => {
            return <QuestionItem key={question.id} question={question} onClick={selectQuestion} currentQuestionId={props.currentQuestionId} />

        })

    }

    const openForm = () => {
        props.dispatch(toggleSidebarAction(true));
    }

    return (
        <div className='private-page quiz-questions'>
            <HeaderContent title='Quiz Questions' />
            {width <= 768 ? <Button text='Add' onClick={openForm} className='mrb-10px width-mc mrl-auto'  icon='add'  /> : <div></div> }            
            <div className="quiz-questions__wrapper">

                <div className="flex fl-col mrr-20px width-100per ">
                    <Input
                        type='search'
                        placeholder='search'
                        onChange={onChange}
                        defaultValue={state.searchValue}
                        searchIcon={true} />
                    <div className="quiz-questions__list">
                        {renderQuestions()}
                        { state.loader &&<Loader color={'#348FE5'} width={40} className='loader--center'/>}
                    </div>
                </div>


                <QuizQuestionsForm  className={state.isOpenForm ? 'order-words__form--active' : ''}/>
            </div>
        </div>
    )
}

const mapStateToProps = (state: IReduxStore) => {
    return {
        questions: state.quizQuestions,
        currentQuestionId: state.currentQuestion.id,
        sidebarForm:state.sidebarForm
    }
}


export default connect(mapStateToProps)(QuizQuestions);