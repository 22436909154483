
export enum WordSearchesTypes {
    GET_WORD_SEARCHES = '@@/word_searches/get_word_searches',
    ADD_WORD_SEARCHES = '@@/word_searches/add_word_searches',
    UPDATE_WORD_SEARCHES = '@@/word_searches/update_word_searches',
    SELECT_WORD_SEARCHES = '@@/word_searches/select_word_searches',
    RESET_WORD_SEARCHES = '@@/word_searches/reset_word_searches',
    DELETE_WORD_SEARCHES = '@@/word_searches/delete_word_searches',


}