import { WordSearches } from "../../../models/WordSearches";
import { WordSearchesAction } from "./wordSearchesAction";
import { WordSearchesTypes } from "./wordSearchesActionTypes";


export const getAllWordSearchesAction = (wordSearches: WordSearches[]) => {
    const GetAllWordSearchesAction: WordSearchesAction = {
        type: WordSearchesTypes.GET_WORD_SEARCHES,
        payload: wordSearches
    }

    return GetAllWordSearchesAction;
}


export const addWordSearchesAction = (wordSearches: WordSearches) => {
    const AddWordSearchesAction: WordSearchesAction = {
        type: WordSearchesTypes.ADD_WORD_SEARCHES,
        payload: wordSearches
    }

    return AddWordSearchesAction;
}


export const updateWordSearchesAction = (wordSearches: WordSearches) => {
    const UpdateWordSearchesAction: WordSearchesAction = {
        type: WordSearchesTypes.UPDATE_WORD_SEARCHES,
        payload: wordSearches
    }

    return UpdateWordSearchesAction;
}

export const deleteWordSearchesAction = (wordSearches: WordSearches) => {
    const DeleteWordSearchesAction: WordSearchesAction = {
        type: WordSearchesTypes.DELETE_WORD_SEARCHES,
        payload: wordSearches
    }

    return DeleteWordSearchesAction;
}

export const selectWordSearchesAction = (wordSearches: WordSearches) => {
    const SelectWordSearchesAction: WordSearchesAction = {
        type: WordSearchesTypes.SELECT_WORD_SEARCHES,
        payload: wordSearches
    }

    return SelectWordSearchesAction;
}


export const resetWordSearchesAction = (wordSearches: WordSearches) => {
    const ResetWordSearchesAction: WordSearchesAction = {
        type: WordSearchesTypes.RESET_WORD_SEARCHES,
        payload: wordSearches
    }

    return ResetWordSearchesAction;
}

