import { WordSearchesAction } from "../actions/WordSearch/wordSearchesAction";
import { WordSearchesTypes } from "../actions/WordSearch/wordSearchesActionTypes";
import { WordSearches } from "../../models/WordSearches";

const initialState: WordSearches = {};

export default (state = initialState, action: WordSearchesAction) => {
    switch (action.type) {
        case WordSearchesTypes.SELECT_WORD_SEARCHES:
            return action.payload;
        case WordSearchesTypes.RESET_WORD_SEARCHES:
            return action.payload;
        default:
            return state;
    }
}