import * as React from 'react'
import { useState, useEffect } from 'react'
import HeaderContent from '../../component/common/HeaderContent';
import Input from '../../component/common/Input';
import OrderWordsForm from '../../component/OrderWords/OrderWordsForm';
import OrderWords from '../../models/OrderWords';
import ListItem from '../../component/OrderWords/ListItem';
import { connect } from 'react-redux'
import { IReduxStore } from '../../redux';
import { getAllOrderWords } from '../../services/OrderWordsService';
import { selectOrderWordsAction } from '../../redux/actions/OrderWords/orderWordsActionCreator';
import Button from '../../component/common/Button';
import { toggleSidebarAction } from '../../redux/actions/Content/contentActionsCreator';
import Loader from '../../component/common/Loader';

export interface IOrderWordProps {
    orderWordsList: OrderWords[],
    dispatch: (action: any) => void,
    currentOrderWordsId: any,
    sidebarForm: boolean
}

let width = window.innerWidth


function OrderWordss(props: IOrderWordProps) {

    const orderWordsMock: OrderWords[] = [];
    const [state, setState] = useState({ inputValue: '', orderWords: orderWordsMock, loader: true, isOpenForm: false });

    useEffect(() => {

        if (state.loader) {
            getAllOrderWords();
        }

        setState({ ...state, orderWords: props.orderWordsList, loader: false });
    }, [props.orderWordsList])

    useEffect(() => {
        setState({ ...state, isOpenForm: props.sidebarForm });
    }, [props.sidebarForm]);


    const onChange = (e: string) => {

        const temp = [];
        setState({ ...state, inputValue: e });
        if (e) {
            for (let i in props.orderWordsList) {
                const orderWordsItem = props.orderWordsList[i];
                const sentence = orderWordsItem.sentence || '';
                if (sentence.toLowerCase().indexOf(e.toLowerCase()) !== -1) {

                    temp.push(orderWordsItem);
                }
            }

            setState({ ...state, inputValue: e, orderWords: temp });
        } else {
            setState({ ...state, inputValue: e, orderWords: props.orderWordsList });

        }
    }

    const onSelectOrderWords = (orderWords: OrderWords) => {
        props.dispatch(selectOrderWordsAction(orderWords));
        setState({ ...state, isOpenForm: true });
        props.dispatch(toggleSidebarAction(true));

    }


    const renderList = () => {
        if (state.orderWords === []) {
            return;
        }
        return state.orderWords.sort((a:any, b:any) => a.level - b.level).map((item, i) => {
            return <ListItem
                key={i}
                orderWords={item}
                id={item.id}
                text={item.sentence}
                onClick={() => onSelectOrderWords(item)}
                currentId={props.currentOrderWordsId} />
        })

    }

    const openForm = () => {
        props.dispatch(toggleSidebarAction(true));
    }

    return (
        <div className='private-page order-words-page'>
            <HeaderContent title='Order Words' />
            {width <= 768 ? <Button text='Add' onClick={openForm} className='mrb-10px width-mc mrl-auto' icon='add' /> : <div></div>}
            <div className="order-words__wrapper">
                <div className="order-words__list">
                    <Input
                        type='search'
                        placeholder='search'
                        onChange={onChange}
                        defaultValue={state.inputValue}
                        searchIcon={true} />
                    <div className='order-word__list'>
                        {renderList()}
                        {state.loader && <Loader color={'#348FE5'} width={40} className='loader--center' />}
                    </div>
                </div>
                <OrderWordsForm className={state.isOpenForm ? 'order-words__form--active' : ''} />
            </div>
        </div>
    )
}

const mapStateToProps = (state: IReduxStore) => {
    return {
        orderWordsList: state.orderWordsList,
        currentOrderWordsId: state.currentOrderWords.id,
        sidebarForm: state.sidebarForm
    }
}

export default connect(mapStateToProps)(OrderWordss)