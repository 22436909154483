import { UserAction } from "./UserAction";
import { UserActionTypes } from "./UserActionTypes";


export const updateUserAction = (user: any) => {
    const UpdateUserAction: UserAction = {
        type: UserActionTypes.UPDATE_USER,
        payload: user
    }

    return UpdateUserAction;
}



export const logOutUserAction = (user: any) => {
    const LogOutUserAction: UserAction = {
        type: UserActionTypes.LOGOUT_USER,
        payload: user
    }

    return LogOutUserAction;
}