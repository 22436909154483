import * as React from 'react';
import { useState, useEffect } from 'react';


interface ISwitchProps {
    className?: string,
    onChange?: (value: boolean) => void,
    defaultValue?: any,
    switchRef?: (switchRef: any) => void,
}



export default function (props: ISwitchProps) {

    const [on, setOn] = useState(props.defaultValue !== undefined ? props.defaultValue : false);

    if (props.switchRef) {
        props.switchRef({ value: on });
    }


    useEffect(() => {
        setOn(props.defaultValue);
    }, [props.defaultValue])

    const switchState = () => {
        setOn(!on);
        if (props.onChange) {
            props.onChange(!on);
        }
    }


    return (
        <div
            onClick={switchState}
            className={
                'switch ' +
                (on ? 'switch--on ' : 'switch--off ') +
                (props.className || '')}>

            <div className={'switch__indicator ' + (on ? 'switch__indicator--on' : 'switch__indicator--off')} />
        </div>
    )
}