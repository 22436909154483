import React from 'react'
import { useState, useEffect } from 'react'
import Input from '../common/Input';
import Button from '../common/Button';
import OptionItem from '../common/OptionItem';
import Time from '../common/Time';
import OrderWords from '../../models/OrderWords';
import { addOrderWords, updateOrderWords } from '../../services/OrderWordsService';
import { connect } from 'react-redux'
import { IReduxStore } from '../../redux';
import { resetCurrentOrderWords } from '../../redux/actions/OrderWords/orderWordsActionCreator';
import { toast } from 'react-toastify';
import { toggleSidebarAction } from '../../redux/actions/Content/contentActionsCreator';

export interface IOrderWordsFormProps {
    currentOrderWords: OrderWords,
    orderWordsList: OrderWords[],
    dispatch: (action: any) => void,
    className?: string,
    sidebarForm: boolean
}


function OrderWordsForm(props: IOrderWordsFormProps) {

    const orderWordsMock: OrderWords = {};

    const [state, setState] = useState({ optionValue: '', wordInputError: false, loading: false, optionSentenceError: false, closeForm: false });

    const [orderWords, setOrderWords] = useState(orderWordsMock)


    useEffect(() => {

        if (props.currentOrderWords) {
            setOrderWords(props.currentOrderWords);
        }
    }, [props.currentOrderWords]);


    useEffect(() => {
        setState({ ...state, closeForm: !props.sidebarForm });
    }, [props.sidebarForm]);


    const onSentenceChange = (sentence: string) => {
        const currentState = orderWords;
        orderWords.level = props.orderWordsList.length + 1;
        orderWords.sentence = sentence;

        setOrderWords({ ...currentState });
    }

    const onOptionChange = (option: string) => {
        const currentState = state;

        currentState.optionValue = option;

        setState({ ...currentState });
    }


    const renderOptions = () => {
        const words = orderWords.words || [];
        return words.map((word, i) => {
            return <OptionItem key={i} text={word} onDelete={deleteWord} className='order-form__option' />
        })
    }


    const addWord = () => {
        const words = orderWords.words || [];
        const sentence = orderWords.sentence || '';

        if (words.join(' ').length > sentence.length) {
            return;
        }

        if (state.optionValue !== '') {
            setOrderWords({ ...orderWords, words: [...words, state.optionValue] });
            setState({ ...state, optionValue: '', wordInputError: false });
        } else {
            setState({ ...state, wordInputError: true });

        }
    }

    const addWordByEnter = (e: any) => {
        var code = e.keyCode || e.which;
        if (code === 13) { //13 is the enter keycode
            //Do stuff in here

            const words = orderWords.words || [];
            const sentence = orderWords.sentence || '';
    
            if (words.join(' ').length > sentence.length) {
                return;
            }
    
            if (state.optionValue !== '') {
                setOrderWords({ ...orderWords, words: [...words, state.optionValue] });
                setState({ ...state, optionValue: '', wordInputError: false });
            } else {
                setState({ ...state, wordInputError: true });
    
            }
        }
    }

    const deleteWord = (text: string) => {
        const words = orderWords.words || [];
        var array = [...words];
        var index = array.indexOf(text)
        if (index !== -1) {
            array.splice(index, 1);
            setOrderWords({ ...orderWords, words: array });
        }

    }
    const onTimeChange = (time: number) => {
        setOrderWords({ ...orderWords, time })
    }


    const saveOrderWords = async () => {

        let error = false;

        const words = orderWords.words || [];
        const sentence = orderWords.sentence || '';

        if (words.join(' ').length !== sentence.length) {
            toast.error('Fjalet nuk e plotesojn fjaline!');
            error = true;
        }

        if (orderWords.time === 0 || !orderWords.time) {
            toast.error('Nuk keni caktuar kohen!');
            error = true;
        }
        if (error) return;

        if (props.currentOrderWords.id) {
            setState({ ...state, loading: true });

            try {
                await updateOrderWords(orderWords);
                toast.success('Updated successfully');
            } catch (e) {
                toast.error(e.Message);

            }
            setState({ ...state, loading: false, optionValue: '' });
            props.dispatch(toggleSidebarAction(false));


        } else {

            setState({ ...state, loading: true });

            try {
                await addOrderWords(orderWords);
                toast.success("Saved successfully");
            } catch (e) {
                toast.error(e.Message);

            }

            setState({ ...state, loading: false, optionValue: '', optionSentenceError: false });
        }

        setOrderWords(orderWordsMock);
        props.dispatch(resetCurrentOrderWords(orderWordsMock));
        props.dispatch(toggleSidebarAction(false));


    }
    const cancelClicked = () => {
        setOrderWords(orderWordsMock);
        setState({ ...state, optionValue: '', });
        props.dispatch(resetCurrentOrderWords(orderWordsMock));
        props.dispatch(toggleSidebarAction(false));


    }


    return (
        <div className={'order-words__form ' + (props.className && state.closeForm === false ? props.className : '')}>
            <span className='form__title'>Order Words Form</span>

            <Input
                type='text'
                placeholder='write sentence'
                className='mrb-10px'
                onChange={onSentenceChange}
                defaultValue={orderWords.sentence ? orderWords.sentence : ''} />

            <div className="flex width-100per responsive-wrapper ">
                <Input
                    type='text'
                    placeholder='write options'
                    className='mrb-10px mrr-10px'
                    onChange={onOptionChange}
                    defaultValue={state.optionValue}
                    error={state.wordInputError} 
                    onKeyPress={addWordByEnter}/>

                <Button text='Add word' className='button--small' onClick={addWord} />
            </div>

            <div className="order-words__options">
                {renderOptions()}
            </div>

            <Time onTimeChange={onTimeChange} time={orderWords.time} />
            <div className="order-words__bottom-btns mrt-10px ">
                <Button text='Cancel' onClick={cancelClicked} className='button--white' icon='close' />
                <Button text={props.currentOrderWords.id ? 'Update' : 'Save'} onClick={saveOrderWords} loading={state.loading} icon='done' />
            </div>
        </div>
    )
}


const mapStateToProps = (state: IReduxStore) => {
    return {
        currentOrderWords: state.currentOrderWords,
        orderWordsList: state.orderWordsList,
        sidebarForm: state.sidebarForm
    }
}

export default connect(mapStateToProps)(OrderWordsForm)