import { WordSearches } from "../../models/WordSearches";
import { WordSearchesAction } from "../actions/WordSearch/wordSearchesAction";
import { WordSearchesTypes } from "../actions/WordSearch/wordSearchesActionTypes";

const initialState: WordSearches[] = [];

export default (state = initialState, action: WordSearchesAction) => {

    switch (action.type) {
        case WordSearchesTypes.GET_WORD_SEARCHES:
            return action.payload;

        case WordSearchesTypes.ADD_WORD_SEARCHES:

            return [...state, action.payload];

        case WordSearchesTypes.UPDATE_WORD_SEARCHES:
            for (let e in state) {
                if (state[e].id === action.payload.id) {
                    state[e] = action.payload;
                    break;
                }
            }

            return state;

        case WordSearchesTypes.DELETE_WORD_SEARCHES:

            return state.filter(wordSearches => {
                return wordSearches.id !== action.payload.id
            })
        default:
            return state;
    }
}
