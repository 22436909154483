import * as React  from 'react'
import {useEffect } from 'react'
import { useState, useRef } from 'react';

export interface ITimeProps {
    onTimeChange: (time: any) => void,
    time: any
}

export default function Time(props: ITimeProps) {

    const minRef = useRef(null);
    const secRef = useRef(null);
    const [state, setState] = useState({ minutes: 0, seconds: 0, time: 0 });

    const onMinutesChange = (e: any) => {
        const time = (+e.target.value * 60) + (+state.seconds);
        setState({ ...state, minutes: e.target.value, time });
    }

    useEffect(() => {
        setState({ ...state, time: props.time });
    }, [props.time]);

    useEffect(() => {
        if (props.onTimeChange) {
            props.onTimeChange(state.time);
        }
    }, [state.time])

    const onSecondsChange = (e: any) => {
        const time = (+state.minutes * 60) + (+e.target.value);
        setState({ ...state, seconds: e.target.value, time });
    }


    const time = state.time;
    let minutes = Math.floor(time / 60);
    let seconds = time - minutes * 60;

    return (
        <div className='time'>
            <div className="flex center">
                <i className="material-icons time__icon">access_time</i>
                <span className='time__text'>Time : </span>
            </div>
            <div className="flex center">
                <input type='number' className='time-input' placeholder='min' ref={minRef} value={minutes ? minutes : 0} onChange={onMinutesChange} />
                :
            <input type='number' className='time-input' placeholder='sec' ref={secRef} value={seconds ? seconds : 0} onChange={onSecondsChange} />
            </div>


        </div>
    )
}
