
export enum QuizQuestionsTypes {
    GET_QUESTIONS = '@@/quiz-questions/get_questions',
    ADD_QUESTION = '@@/quiz-questions/add_question',
    UPDATE_QUESTION = '@@/quiz-questions/update_question',
    SELECT_QUESTION = '@@/quiz-questions/select_question',
    RESET_QUESTION = '@@/quiz-questions/reset_question',
    DELETE_QUESTION = '@@/quiz-questions/delete_question',


}