import React from 'react'
import { useState, useEffect } from 'react';
import Input from '../common/Input';
import Button from '../common/Button';
import OptionItem from '../common/OptionItem';
import Time from '../common/Time';
import { connect } from 'react-redux';
import { IReduxStore } from '../../redux';
import { WordSearches } from '../../models/WordSearches';
import { resetWordSearchesAction } from '../../redux/actions/WordSearch/wordSearchesActionCreator';
import { updateWordSearches, addWordSearches } from '../../services/WordSearchesServices';
import { toast } from 'react-toastify';
import { toggleSidebarAction } from '../../redux/actions/Content/contentActionsCreator';

export interface IWordSearchesProps {
    currentWordSearches: WordSearches,
    wordSearchesList: WordSearches[],
    dispatch: (action: any) => void,
    className?: string,
    sidebarForm: boolean,
}

function WordSearchesForm(props: IWordSearchesProps) {


    const wordSearchesMock: WordSearches = {};
    const [state, setState] = useState({
        optionValue: '',
        optionInputError: false,
        loading: false,
        quInputError: false,
        timeError: false,
        optionError: false,
        closeForm: false
    });

    const [wordSearches, setWordSearches] = useState(wordSearchesMock);

    useEffect(() => {
        if (props.currentWordSearches) {
            setWordSearches(props.currentWordSearches);
        }
    }, [props.currentWordSearches]);

    useEffect(() => {
        setState({ ...state, closeForm: !props.sidebarForm });
    }, [props.sidebarForm]);

    const onGridSizeChange = (gridSize: number) => {

        const currentWordSearches = wordSearches;
        currentWordSearches.gridSize = +gridSize;
        currentWordSearches.level = +props.wordSearchesList.length + 1;
        setState({ ...state, quInputError: false });
        setWordSearches({ ...currentWordSearches });
    }

    const onOptionChange = (value: string) => {
        setState({ ...state, optionValue: value })
    }
    const onTimeChange = (time: any) => {
        setWordSearches({ ...wordSearches, time });
    }

    const renderWords = () => {

        const words = wordSearches.words || [];
        return words.map((word, i) => {

            return <OptionItem
                key={i}
                text={word}
                onDelete={deleteOption}
                className={'word-searches-form__option'} />

        })

    }

    const addOption = () => {
        const words = wordSearches.words || [];
        setWordSearches({ ...wordSearches, words: [...words, state.optionValue.toUpperCase()] });
        setState({ ...state, optionValue: '', optionInputError: false });

    }

    const addOptionByEnter = (e: any) => {
        var code = e.keyCode || e.which;
        if (code === 13) { //13 is the enter keycode
            //Do stuff in here
            const words = wordSearches.words || [];
            setWordSearches({ ...wordSearches, words: [...words, state.optionValue.toUpperCase()] });
            setState({ ...state, optionValue: '', optionInputError: false });

        }
    }
    const deleteOption = (text: string) => {
        const words = wordSearches.words || [];
        var array = [...words];
        var index = array.indexOf(text)
        if (index !== -1) {
            array.splice(index, 1);
            setWordSearches({ ...wordSearches, words: array });
        }

    }


    const saveWordSerches = async () => {

        const words = wordSearches.words || [];
        const gridSize = wordSearches.gridSize || 0;
        let wordLengthError = false;
        let error = false;
        if (wordSearches.time === 0 || !wordSearches.time) {
            toast.error('Nuk keni caktuar kohen!');
            error = true;
        }

        if (words.length < gridSize) {
            toast.error('Keni me pak se ' + wordSearches.gridSize + ' fjale');
            error = true;
        }

        words.forEach(word => {
            if (word.length > gridSize) {
                wordLengthError = true;

            }
        });

        if (wordLengthError && gridSize >= 3) {
            toast.error('Fjalet nuk duhet te permbajn me shume se ' + wordSearches.gridSize + ' karaktere');
            error = true;
        }

        if (gridSize < 3) {
            toast.error('Grid size nuk duhet te jete me pak se 3');
            error = true;
        }
        if (error) return;

        if (props.currentWordSearches.id) {
            setState({ ...state, loading: true });

            try {
                await updateWordSearches(wordSearches);
                toast.success('Updated successfully');
                setWordSearches(wordSearchesMock);
                props.dispatch(resetWordSearchesAction(wordSearchesMock));
                props.dispatch(toggleSidebarAction(false));

            } catch (e) {
                toast.error(e.Message);
            }
            setState({ ...state, loading: false, optionValue: '' });

        } else {
            setState({ ...state, loading: true });

            try {
                await addWordSearches(wordSearches);
                toast.success('Saved successfully');
                setWordSearches(wordSearchesMock);
                props.dispatch(resetWordSearchesAction(wordSearchesMock));
                props.dispatch(toggleSidebarAction(false));

            } catch (e) {
                toast.error(e.Message);
            }
            setState({ ...state, loading: false, optionValue: '' });

        }


    }

    const onCancelClicked = () => {
        setWordSearches(wordSearchesMock);
        props.dispatch(resetWordSearchesAction(wordSearchesMock));
        props.dispatch(toggleSidebarAction(false));
        setState({ ...state, optionValue: '' })
    }

    return (
        <div className={'quiz-questions__question-form ' + (props.className && state.closeForm === false ? props.className : '')}>
            <span className='form__title'>Word Searches Form</span>



            <div className="flex width-100per responsive-wrapper">
                <Input
                    type='text'
                    placeholder='size'
                    className='mrb-10px width-60px mrr-10px'
                    onChange={onGridSizeChange}
                    defaultValue={wordSearches.gridSize ? wordSearches.gridSize : ''} />

                <Input
                    type='text'
                    placeholder='write options'
                    className='mrb-10px mrr-10px'
                    onChange={onOptionChange}
                    defaultValue={state.optionValue}
                    error={state.optionInputError}
                    onKeyPress={addOptionByEnter} />

                <Button text='Add word' className='button--small' onClick={addOption} />
            </div>

            <div className="flex fl-col">
                <div className="word-searches-form__options-list">
                    {renderWords()}
                </div>
            </div>

            <Time onTimeChange={onTimeChange} time={wordSearches.time} />
            <div className="order-words__bottom-btns mrt-10px ">
                <Button text='Cancel' onClick={onCancelClicked} className='button--white' icon='close' />
                <Button text={props.currentWordSearches.id ? 'Update' : 'Save'} onClick={saveWordSerches} loading={state.loading} icon='done' />
            </div>
        </div>
    )
}

const mapStateToProps = (state: IReduxStore) => {
    return {
        currentWordSearches: state.currentWordSearches,
        wordSearchesList: state.wordSearches,
        sidebarForm: state.sidebarForm
    }
}

export default connect(mapStateToProps)(WordSearchesForm)