import React from 'react'
import { connect } from 'react-redux';
import { IReduxStore } from '../../redux';
import { logOutUserAction } from '../../redux/actions/User/UserActionsCreator';
import { toggleSidebarAction } from '../../redux/actions/Content/contentActionsCreator';

export interface IHeaderContentProps {
    title: string,
    user: any,
    dispatch: (action: any) => void
}
function HeaderContent(props: IHeaderContentProps) {


    const logOutUser = () => {
        props.dispatch(logOutUserAction({}));
    }

    const openMenu = () => {
        props.dispatch(toggleSidebarAction(true));
    }
    return (
        <div className='header-content'>
            <i className="material-icons header-content__menu " onClick={openMenu}>menu</i>
            <span className='header-content__title'>{props.title}</span>
            
        </div>
    )
}


const mapStateToProps = (state: IReduxStore) => {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps)(HeaderContent)