import React from 'react'
import LoginForm from '../component/logIn/LoginForm';

export default function LogIn() {
    return (
        <div className='login-page'>
            
            <div className='login-page__logo'/>
            <LoginForm />
        </div>
    )
}
