import * as React from 'react'
import Input from '../common/Input';
import Button from '../common/Button';
import { connect } from 'react-redux';
import { IReduxStore } from '../../redux';
import { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { updateUserAction } from '../../redux/actions/User/UserActionsCreator';
import Switch from '../common/Switch';
import { signInUser } from '../../services/UserService';
import axios from 'axios'
import { API } from '../../config';

export interface ILoginFormProps {
    user: any,
    dispatch: (action: any) => void
}


function LoginForm(props: ILoginFormProps) {

    if (localStorage.getItem('user') === null) {
        localStorage.setItem('user', JSON.stringify({}));

    }
    const userJson = localStorage.getItem('user');
    const localUserMock = JSON.parse(userJson || '');

    const [user, setUser] = useState({ username: '', password: '', signIn: false, msgError: false, localUser: localUserMock, rememberMe: false, loading: false })

    useEffect(() => {
        props.dispatch(updateUserAction(localUserMock));

    }, []);

    const onEmailChange = (email: string) => {
        setUser({ ...user, username: email });
        const userState = {
            id: 'id123',
            username: email,
            password: user.password
        }
        props.dispatch(updateUserAction(userState));
    }


    const onPasswordChange = (pass: string) => {
        setUser({ ...user, password: pass });
        const userState = {
            id: 'id123',
            username: user.username,
            password: pass
        }
        props.dispatch(updateUserAction(userState));
    }

    const onRememberChange = (value: any) => {
        setUser({ ...user, rememberMe: value });

        if (!value) {
            localStorage.setItem('user', JSON.stringify({}));
        }

    }



    const signIn = async () => {


        setUser({ ...user, loading: true });

        try {
            await signInUser(props.user.username, props.user.password);
            axios.get(API + 'order-words').then(res => {
                setUser({ ...user, signIn: true, msgError: false, });
            }).catch(e => {
                setUser({ ...user, msgError: true, loading: false });
            });

            if (user.rememberMe) {
                const localUser = JSON.stringify({ id: props.user.id, username: props.user.username, password: props.user.password, rememberMe: user.rememberMe });
                localStorage.setItem('user', localUser);
            }

        } catch (e) {

        }




    }

    const toAdmin = () => {
        if (user.signIn) {
            return <Redirect to='/order-words' />
        }
    }
    return (
        <div className='login-form'>
            <div className='header-content'>
                <span className='header-content__title'>SIGN IN</span>
            </div>
            <span className='login-form__placeholder'>Username:</span>
            <Input type='text' placeholder='username' defaultValue={user.localUser ? user.localUser.username : ''} className='mrb-10px ' emailIcon={true} onChange={onEmailChange} />

            <span className='login-form__placeholder'>Password:</span>
            <Input type='password' placeholder='******' defaultValue={user.localUser ? user.localUser.password : ''} className='mrb-10px ' passwordIcon={true} onChange={onPasswordChange} />

            <div className="flex">
                <span className='login-form__placeholder mrr-10px'>Remember me:</span>
                <Switch defaultValue={user.localUser ? user.localUser.rememberMe : user.rememberMe} onChange={onRememberChange} />

            </div>
            <Button text='SIGN IN' className='width-60px mrt-10px' onClick={signIn} loading={user.loading} />
            {user.msgError && <span className='error-msg'>Your Email or Password is incorrect</span>}

            {toAdmin()}


        </div >
    )
}

const mapStateToProps = (state: IReduxStore) => {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps)(LoginForm)