import React from 'react'
import { Route, Switch } from "react-router-dom";
import { ProtectedRoutes} from '../Routes';

export default function Content() {
    return (
        <div className = 'main-content'>
            <Switch>
                <Route exact path='/order-words' component={ProtectedRoutes.OrderWords} />
                <Route path='/quiz-questions' component={ProtectedRoutes.QuizQuestions} />
                <Route path='/word-search' component={ProtectedRoutes.WordSearch} />
            </Switch>
        </div>
    )
}
