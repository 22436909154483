import React from 'react';
import { useEffect } from 'react'
import { Route, Switch } from "react-router-dom";
import { ProtectedRoutes, PublicRoutes } from './screens/Routes';
import { toast } from 'react-toastify';

const App: React.FC = () => {


  useEffect(() => {
    toast.configure({
      position: "bottom-center",
      newestOnTop: false,
      closeOnClick: true,
      rtl: false,
      draggable: true,
    })
  }, []);

  return (
    <div className="App">
      <Switch>
        <Route path='/login' component={PublicRoutes.LogIn} />
        <Route component={ProtectedRoutes.Main} />
      </Switch>
    </div>
  );
}

export default App;
