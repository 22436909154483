import OrderWords from "../../models/OrderWords";
import { OrderWordsAction } from "../actions/OrderWords/orderWordsAction";
import { OrderWordsTypes } from "../actions/OrderWords/orderWordsActionTypes";

const initialState: OrderWords[] = [];

export default (state = initialState, action: OrderWordsAction) => {

    switch (action.type) {
        case OrderWordsTypes.GET_ORDER_WORDS:
            return action.payload;

        case OrderWordsTypes.ADD_ORDER_WORDS:

            return [...state, action.payload];

        case OrderWordsTypes.UPDATE_ORDER_WORDS:
            for (let e in state) {
                if (state[e].id === action.payload.id) {
                    state[e] = action.payload;
                    break;
                }
            }

            return state;
        case OrderWordsTypes.DELETE_ORDER_WORDS:
            return state.filter(item => {
                return item.id !== action.payload.id
            })

        default:
            return state;
    }
}