
import axios from 'axios'
import { store } from '../redux';
import { logOutUserAction } from '../redux/actions/User/UserActionsCreator';


export const signInUser = (username: any, password: string) => {

    const token = btoa(username + ':' + password);

    axios.defaults.headers.common['Authorization'] = 'Basic ' + token;

    return token;
}


export  const logOutUser = () => {

    store.dispatch(logOutUserAction({}));
}