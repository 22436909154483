import QuizQuestion from "../../models/QuizQuestion";
import { QuizQuestionsAction } from "../actions/QuizQuestions/quizQuestionsAction";
import { QuizQuestionsTypes } from "../actions/QuizQuestions/quizQuestionsType";

const initialState: QuizQuestion[] = [];

export default (state = initialState, action: QuizQuestionsAction) => {

    switch (action.type) {
        case QuizQuestionsTypes.GET_QUESTIONS:
            return action.payload;

        case QuizQuestionsTypes.ADD_QUESTION:

            return [...state, action.payload];

        case QuizQuestionsTypes.UPDATE_QUESTION:
            for (let e in state) {
                if (state[e].id === action.payload.id) {
                    state[e] = action.payload;
                    break;
                }
            }

            return state;

        case QuizQuestionsTypes.DELETE_QUESTION:

            return state.filter(question => {
                return question.id !== action.payload.id
            })
        default:
            return state;
    }
}
