
import { API } from '../config';
import { store } from '../redux';
import { getAllOrderWordsAction, addOrderWordsAction, updateOrderWordsAction, resetCurrentOrderWords } from '../redux/actions/OrderWords/orderWordsActionCreator';
import OrderWords from '../models/OrderWords';
import axios from 'axios'


export const getAllOrderWords = async () => {
    axios.get(API + 'order-words')
        .then(response => {
            store.dispatch(getAllOrderWordsAction(response.data));
        }).catch(e => {
            console.log(e);
        })
}

export const addOrderWords = async (orderWords: OrderWords) => {
    await axios.post(API + 'order-words', orderWords)
        .then(response => {
            store.dispatch(addOrderWordsAction(response.data));
            store.dispatch(resetCurrentOrderWords({}));
        })
}


export const updateOrderWords = async (orderWords: OrderWords) => {

    await axios.put(API + `order-words/${orderWords.id}`, orderWords)
        .then(response => {
            store.dispatch(updateOrderWordsAction(response.data));
            store.dispatch(resetCurrentOrderWords({}));

        })
}

