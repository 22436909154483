
import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { History } from 'history'
import { IReduxStore } from '..';
import OrderWordsList from './orderWords'
import User from './User';
import CurrentOrderWords from './currentOrderWords';
import QuizQuestions from './quizQuestions';
import CurrentQuestion from './currentQuestion';
import WordSearches from './wordSearches';
import CurrentWordSearches from './currentWordSearches';
import sidebar from './sidebar';

export const Reducers = (history: History) => combineReducers<IReduxStore>({
    user: User,
    orderWordsList: OrderWordsList,
    currentOrderWords: CurrentOrderWords,
    quizQuestions: QuizQuestions,
    currentQuestion: CurrentQuestion,
    wordSearches: WordSearches,
    currentWordSearches: CurrentWordSearches,
    sidebarForm:sidebar,
    router: connectRouter(history),
});