import { ContentAction } from "./contentAction";
import { ContentTypes } from "./contentActionTypes";


export const toggleSidebarAction = (isOpen: boolean) => {

    const ToggleSidebarAction: ContentAction = {
        type: ContentTypes.TOGGLE_MENU,
        payload: isOpen
    }

    return ToggleSidebarAction;
}