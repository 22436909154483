import React from 'react'
import QuizQuestion from '../../models/QuizQuestion';

export interface IQuestionItemProps {
    question: QuizQuestion,
    currentQuestionId: any,
    onClick: (question: QuizQuestion) => void
}
export default function QuestionItem(props: IQuestionItemProps) {


    const renderOptions = () => {
        const options = props.question.options || [];
        return options.map((option, i) => {
            const active = props.question.answer === option;

            return <span key={i} className={'question-item__option ' + (active ? 'question-item__option--active' : '')}>{option}</span>
        })
    }

    

    const active = props.question.id === props.currentQuestionId;
    return (
        <div className={'quiz-questions__question-item ' + (active ? 'quiz-questions__question-item--active' : '')} onClick={() => props.onClick(props.question)}>
            <div className="question-item__question-text">{props.question.level + '. '}{props.question.question} </div>

            <div className="question-item__options-list">
                {renderOptions()}
            </div>

            {active ? <i className="material-icons question-item_edit-icon">create</i> : ''}

        </div>
    )
}
