import React from 'react'
import { resetCurrentOrderWords } from '../redux/actions/OrderWords/orderWordsActionCreator';
import { connect } from 'react-redux';
import { IReduxStore } from '../redux';
import { resetCurrentQuestionAction } from '../redux/actions/QuizQuestions/quizQuestionsActionsCreator';
import { resetWordSearchesAction } from '../redux/actions/WordSearch/wordSearchesActionCreator';
import { toggleSidebarAction } from '../redux/actions/Content/contentActionsCreator';

export interface ISidebarItemProps {
    text: string,
    icon: string,
    path: string,
    currentUrl: string,
    changeRoute: (route: string) => void;
    dispatch: (action: any) => void

}
function SidebarItem(props: ISidebarItemProps) {

    const active = props.currentUrl === props.path;

    const navigateToRoute = () => {
        if (!active) {
            props.changeRoute(props.path);
            props.dispatch(resetCurrentOrderWords({}));
            props.dispatch(resetCurrentQuestionAction({}));
            props.dispatch(resetWordSearchesAction({}));
            props.dispatch(toggleSidebarAction(false));


        }
    }
    return (
        <div onClick={navigateToRoute} className={'sidebar__sidebar-item ' + (active ? 'sidebar__sidebar-item---active' : '')}>
            <i className={"material-icons sidebar-item__icon " + (active ? 'sidebar__icon--active' : '')}>{props.icon}</i>
            <div className='sidebar-item__text'>{props.text}</div>
        </div>
    )
}

const mapStateToProps = (state: IReduxStore) => {
    return {

    }
}

export default connect(mapStateToProps)(SidebarItem)