
import { API } from '../config';
import { store } from '../redux';
import { getAllQuestionsAction, addQuestionAction, updateQuestionAction, resetCurrentQuestionAction } from '../redux/actions/QuizQuestions/quizQuestionsActionsCreator';
import QuizQuestion from '../models/QuizQuestion';
import axios, { AxiosResponse } from 'axios';

export const getAllQuestions = async () => {
    axios.get(API + 'quiz-questions')
        .then((response: AxiosResponse) => {
            store.dispatch(getAllQuestionsAction(response.data));
        })
}

export const addQuestions = async (question: QuizQuestion) => {
    await axios.post(API + 'quiz-questions', question)
        .then((response: AxiosResponse) => {
            store.dispatch(addQuestionAction(response.data));
            store.dispatch(resetCurrentQuestionAction({}));
        })
}


export const updateQuestion = async (question: QuizQuestion) => {

    await axios.put(API + `quiz-questions/${question.id}`, question)
        .then((response: AxiosResponse) => {
            store.dispatch(updateQuestionAction(response.data));
            store.dispatch(resetCurrentQuestionAction({}));

        })
}

