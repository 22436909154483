import * as React from 'react'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import HeaderContent from '../../component/common/HeaderContent';
import Input from '../../component/common/Input';
import { IReduxStore } from '../../redux';
import { WordSearches } from '../../models/WordSearches';
import { getAllWordsSearches } from '../../services/WordSearchesServices';
import WordSearchesItem from '../../component/WordSearches/WordSearchesItem';
import WordSearchesForm from '../../component/WordSearches/WordSearchesForm';
import { selectWordSearchesAction } from '../../redux/actions/WordSearch/wordSearchesActionCreator';
import Loader from '../../component/common/Loader';
import { toggleSidebarAction } from '../../redux/actions/Content/contentActionsCreator';
import Button from '../../component/common/Button';

export interface IWordSearchesProps {
    wordSearchesList: WordSearches[]
    currentWordSearchesId: any,
    dispatch: (action: any) => void,
    sidebarForm:boolean
}

let width = window.innerWidth


function WordSearchesComponent(props: IWordSearchesProps) {
    const wordSearchesMock: WordSearches[] = [];
    const [state, setState] = useState({ inputValue: '', wordSearches: wordSearchesMock, loader: true,isOpenForm:false });

    useEffect(() => {

        if (state.loader) {
            getAllWordsSearches();
        }

        setState({ ...state, wordSearches: props.wordSearchesList, loader: false });
    }, [props.wordSearchesList])

    
    useEffect(() => {
        setState({ ...state, isOpenForm: props.sidebarForm });
    }, [props.sidebarForm]);

    const onChange = (e: any) => {

    }

    const onSelectWordSearches = (wordSearches: WordSearches) => {
        props.dispatch(selectWordSearchesAction(wordSearches));
        props.dispatch(toggleSidebarAction(true));
    }

    const renderWordSearchesItem = () => {
        
        return state.wordSearches.sort((a:any, b:any) => a.level - b.level).map((wordSearch, i) => {
            return <WordSearchesItem
                key={i}
                wordSearches={wordSearch}
                onClick={() => onSelectWordSearches(wordSearch)}
                currentId={props.currentWordSearchesId} />
        })
    }

    const openForm = () => {
        props.dispatch(toggleSidebarAction(true));
    }
    return (
        <div className='private-page word-searches'>
            <HeaderContent title='Word Searches' />
            {width <= 768 ? <Button text='Add' onClick={openForm} className='mrb-10px width-mc mrl-auto'  icon='add'  /> : <div></div> }
            <div className="quiz-questions__wrapper">

                <div className="flex fl-col mrr-20px width-100per">
                    <Input
                        type='search'
                        placeholder='search'
                        onChange={onChange}
                        defaultValue={''}
                        searchIcon={true} />

                    <div className="quiz-questions__list">
                        {renderWordSearchesItem()}
                        { state.loader &&<Loader color={'#348FE5'} width={40} className='loader--center'/>}                       
                    </div>

                </div>
                <WordSearchesForm className={state.isOpenForm ? 'order-words__form--active' : ''} />

            </div>
        </div>

    )
}


const mapStateToProps = (state: IReduxStore) => {
    return {
        wordSearchesList: state.wordSearches,
        currentWordSearchesId: state.currentWordSearches.id,
        sidebarForm:state.sidebarForm
    }
}

export default connect(mapStateToProps)(WordSearchesComponent)